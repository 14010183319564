//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getDictFLJ,
         getDictionary,
         getNewVfaDate,
         getLang } from '~/utils/butterUtils'
import { STATE_ISO_CODE,
         getVFAParameters } from '~/utils/VFAParameters'

export default {
  head () {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.url+this.$route.path
        },
        {
          rel: 'alternate',
          href: '/',
          hreflang: 'x-default'
        },
        {
          rel: 'alternate',
          href: '/esES',
          hreflang: 'x-default'
        },
      ]
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/index.vue asyncData ')
  },
  data () {
    return {
      selectedState: null
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    isStudentSite () {
      return process.env.isStudentSite === 'true' || this.$store.state.isSimulatedStudentSite
    },
    preferredLanguage () {
      return navigator.language
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    pageMetaDescription () {
      return this.dict.SEO_A06
    },
    pageTitle () {
      return process.env.siteTitle + " | " + this.dict.SEO_A05
    },
    statesSortedLocalized () {
      return STATE_ISO_CODE
        .map(x => ({iso: x, name: this.getDictFLJ( `states.${x}`, this.dict)}))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    buttonBackground () {
      return this.butterVFAGraphics["landing_page_new_voter_button_"+this.lang]
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics.landing_page : null
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE () {
      return this.VFAParameters.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE
    },
  },
  methods: {
    goBegin (selectedState) {
      const lsObj = JSON.stringify(Object.assign({}, this.$store.state.votingState, {'selectedState': selectedState}, {'expiryTime': getNewVfaDate().getTime()+this.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE*1000}))
      localStorage.setItem('localstoragevotingstate', lsObj)
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.commit('saveSelectedState', selectedState)
      this.$router.push(this.localePath({ name: 'request-stage', params :{ stage: 'start-fpca' } }))
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  },
  mounted () {
    this.$store.commit('saveSelectedState', '')
    localStorage.removeItem('localstoragevotingstate')

    /*
    new PerformanceObserver((entryList) => {
      for (const entry of entryList.getEntries()) {
        console.log('LCP candidate:', entry.startTime, entry);
      }
    }).observe({type: 'largest-contentful-paint', buffered: true});
    */
  },
  transition: 'test'
}
